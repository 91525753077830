/*
setting = {
  element: document.querySelector('.lorem'),
  destination: 500,
  duration: 250,
  easing: 'easeInOutQuad'
  horizontal: false,
  callback: function(){}
}
*/

function scrollTo(_settings) {
  // define timing functions
  const easings = {
    linear(t) {
      return t;
    },
    easeInQuad(t) {
      return t * t;
    },
    easeOutQuad(t) {
      return t * (2 - t);
    },
    easeInOutQuad(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    },
    easeInCubic(t) {
      return t * t * t;
    },
    easeOutCubic(t) {
      return (--t) * t * t + 1;
    },
    easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    },
    easeInQuart(t) {
      return t * t * t * t;
    },
    easeOutQuart(t) {
      return 1 - (--t) * t * t * t;
    },
    easeInOutQuart(t) {
      return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
    },
    easeInQuint(t) {
      return t * t * t * t * t;
    },
    easeOutQuint(t) {
      return 1 + (--t) * t * t * t * t;
    },
    easeInOutQuint(t) {
      return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
    },
  };

  // Returns document.documentElement for Chrome and Safari
  // document.body for rest of the world
  function getBody() {
    document.documentElement.scrollTop += 1;
    const body = (document.documentElement.scrollTop !== 0) ? document.documentElement : document.body;
    document.documentElement.scrollTop -= 1;
    return body;
  }

  const settings = _settings || {};
  const element = settings.element ? settings.element : getBody();
  let destination = settings.destination ? settings.destination : 0;
  const duration = settings.duration ? settings.duration : 250;
  const easing = settings.easing ? settings.easing : 'easeInOutQuad';
  const horizontal = settings.horizontal ? settings.horizontal : false;
  const callback = settings.easing ? settings.easing : false;

  if (destination < 0) {
    destination = 0;
  }

  const start = horizontal ? element.scrollLeft : element.scrollTop;
  const startTime = Date.now();

  function scroll() {
    const now = Date.now();
    const time = Math.min(1, ((now - startTime) / duration));
    const timeFunction = easings[easing](time);
    if (horizontal) {
      element.scrollLeft = (timeFunction * (destination - start)) + start;
    } else {
      element.scrollTop = (timeFunction * (destination - start)) + start;
    }

    if (time === 1) {
      if (typeof callback === 'function') {
        callback();
      }
    } else {
      requestAnimationFrame(scroll);
    }
  }
  scroll();
}

export default scrollTo;
