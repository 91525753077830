/*
globals TRANSITION_DURATION
*/

class ProductsTeaser {
  constructor(element) {
    function handlePrevClick() {
      self.showPrevElement();
    }
    function handleNextClick() {
      self.showNextElement();
    }
    function handleTouchStart(event) {
      changeX = 0;
      changeY = 0;
      lockScrollDirectionX = false;
      lockScrollDirectionY = false;
      touchStartX = event.changedTouches[0].pageX;
      touchStartY = event.changedTouches[0].pageY;
      self.listElement.style.transitionDuration = '0s';
    }
    function handleTouchMove(event) {
      changeX = (event.changedTouches[0].pageX - touchStartX) / 2;
      changeY = (event.changedTouches[0].pageY - touchStartY) / 2;
      if (!lockScrollDirectionY) {
        window.requestAnimationFrame(() => {
          self.listElement.style.transform = `translateX(calc(-${self.activeItem}00% + ${changeX}px))`;
        });
      }
      if (lockScrollDirectionX) {
        event.preventDefault();
      }
      if ((changeX > 5 || changeX < -5) && !lockScrollDirectionY) {
        lockScrollDirectionX = true;
      }
      if ((changeY > 5 || changeY) < -5 && !lockScrollDirectionX) {
        lockScrollDirectionY = true;
      }
    }
    function handleTouchEnd() {
      self.listElement.style.transitionDuration = '';
      self.listElement.style.transform = `translateX(-${self.activeItem}00%)`;
      if (changeX < -25) {
        self.showNextElement();
      } else if (changeX > 25) {
        self.showPrevElement();
      }
      lockScrollDirectionX = false;
      lockScrollDirectionY = false;
    }
    function handleTouchCancel() {
      lockScrollDirectionX = false;
      lockScrollDirectionY = false;
    }

    const self = this;
    let touchStartX = 0;
    let touchStartY = 0;
    let changeX = 0;
    let changeY = 0;
    let lockScrollDirectionX = false;
    let lockScrollDirectionY = false;
    this.element = element;
    this.listElement = element.querySelector('.products-teaser__list');
    this.itemElements = Array.from(element.querySelectorAll('.products-teaser__item'));
    this.activeItem = 0;
    this.firstListElement = this.itemElements[0];
    this.activeListElement = this.firstListElement;
    this.prevBttnElement = element.querySelector('.products-teaser__bttn--prev');
    this.nextBttnElement = element.querySelector('.products-teaser__bttn--next');

    this.prevBttnElement.onclick = handlePrevClick;
    this.nextBttnElement.onclick = handleNextClick;
    this.element.ontouchstart = handleTouchStart;
    this.element.ontouchmove = handleTouchMove;
    this.element.ontouchend = handleTouchEnd;
    this.element.ontouchcancel = handleTouchCancel;

    this.startAnimation();
  }

  startAnimation() {
    this.listElement.style.transform = 'translateX(100%)';
    setTimeout(() => {
      this.activeListElement.classList.remove('is-hidden');
      this.nextBttnElement.classList.remove('is-inactive');
      this.listElement.style.transform = '';
    }, 250);
  }

  updateBttn() {
    if (this.activeItem + 1 === this.itemElements.length) {
      this.nextBttnElement.classList.add('is-inactive');
    } else {
      this.nextBttnElement.classList.remove('is-inactive');
    }
    if (this.activeItem === 0) {
      this.prevBttnElement.classList.add('is-inactive');
    } else {
      this.prevBttnElement.classList.remove('is-inactive');
    }
  }

  showPrevElement() {
    if (this.activeItem - 1 >= 0) {
      this.activeItem -= 1;
      this.activeListElement.classList.add('is-hidden');
      this.activeListElement = this.itemElements[this.activeItem];
      this.activeListElement.classList.remove('is-hidden');
      this.listElement.style.transform = `translateX(-${this.activeItem}00%)`;
    } else {
      this.listElement.style.transform = `translateX(${this.activeItem}30%)`;
      setTimeout(() => {
        this.listElement.style.transform = `translateX(-${this.activeItem}00%)`;
      }, TRANSITION_DURATION * 0.5);
    }
    this.updateBttn();
  }

  showNextElement() {
    if (this.activeItem + 1 < this.itemElements.length) {
      this.activeItem += 1;
      this.activeListElement.classList.add('is-hidden');
      this.activeListElement = this.itemElements[this.activeItem];
      this.activeListElement.classList.remove('is-hidden');
      this.listElement.style.transform = `translateX(-${this.activeItem}00%)`;
    } else {
      this.listElement.style.transform = `translateX(-${this.activeItem}30%)`;
      setTimeout(() => {
        this.listElement.style.transform = `translateX(-${this.activeItem}00%)`;
      }, TRANSITION_DURATION * 0.5);
    }
    this.updateBttn();
  }
}

export default ProductsTeaser;
