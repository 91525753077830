/*
globals TRANSITION_DURATION
*/

class Detail {
  constructor(element) {
    function handleClick(event) {
      if (!self.element.classList.contains('is-open')) {
        self.open();
      } else {
        self.close();
      }
      event.preventDefault();
    }
    const self = this;
    this.element = element;
    this.summaryElement = element.querySelector('.detail__summary');
    this.summaryElement.onclick = handleClick;
  }

  open() {
    this.element.open = true;
    const newHeight = this.element.offsetHeight;
    this.element.style.height = `${this.summaryElement.offsetHeight}px`;
    this.element.offsetHeight;
    this.element.style.height = `${newHeight}px`;
    this.element.classList.add('is-open');
    setTimeout(() => {
      this.element.style.height = '';
    }, TRANSITION_DURATION);
  }

  close() {
    this.element.style.height = `${this.element.offsetHeight}px`;
    this.element.offsetHeight;
    this.element.style.height = `${this.summaryElement.offsetHeight}px`;
    this.element.classList.remove('is-open');
    setTimeout(() => {
      this.element.open = false;
      this.element.style.height = '';
    }, TRANSITION_DURATION);
  }
}

export default Detail;
