/*
globals NAVIGATION ga
*/

class Link {
  constructor(element) {
    function handleClick(event) {
      try {
        if (!element.href.includes('#') && !event.metaKey && !event.shiftKey && !event.ctrlKey && !event.altKey) {
          NAVIGATION.openLink(url);
          event.preventDefault();
        }
      } catch (exception) {
        console.error(exception);
      }
    }
    const url = element.href;
    if (url !== location.href
      && !url.includes('#')
      && !url.startsWith('mailto:')
      && !url.startsWith('tel:')
      && url.startsWith(location.origin)
      && element.target.length === 0
      && !element.hasAttribute('download')) {
      // use this method (instead of addEventListener) to prevent multiple onclick event listeners
      element.onclick = handleClick;
    } else {
      // reset
      element.onclick = null;
    }
  }
}

export default Link;
