/*
globals TRANSITION_DURATION
*/

class Compare {
  constructor() {
    function handleHashChange() {
      const { hash } = location;
      if (hash.substr(0, 12) === '#vergleiche=') {
        self.uids = hash.substr(12);
        self.show();
      } else if (!self.element.hidden) {
        self.hide();
      }
    }
    const self = this;
    this.element = document.querySelector('.compare');
    this.textElement = this.element.querySelector('.compare__text');
    this.bodyElement = document.querySelector('body');
    this.scrollTop = 0;
    window.removeEventListener('hashchange', handleHashChange);
    window.addEventListener('hashchange', handleHashChange);
    handleHashChange();
    this.uids;
  }

  loadData() {
    let url = `/vergleiche?products=${this.uids}`;
    if (location.pathname.startsWith('/en/')) {
      url = `/en/vergleiche?products=${this.uids}`;
    }
    return fetch(url, {
      credentials: 'omit',
    }).then((response) => {
      return response.text();
    }).then((html) => html);
  }

  show() {
    this.loadData().then((html) => {
      this.textElement.innerHTML = html;
      this.element.classList.add('is-hidden');
      this.element.hidden = false;
      this.element.offsetWidth;
      this.element.classList.remove('is-hidden');
      this.scrollTop = this.bodyElement.scrollTop;
      this.bodyElement.style.overflow = 'hidden';
    });
  }

  hide() {
    this.bodyElement.style.overflow = '';
    this.bodyElement.scrollTop = this.scrollTop;
    this.element.classList.add('is-hidden');
    setTimeout(() => {
      this.textElement.innerHTML = '';
      this.element.hidden = true;
    }, TRANSITION_DURATION * 2);
  }
}

export default Compare;
