/* globals NAVIGATION */

class BreadcrumbItem {
  constructor(element, i) {
    function startAnimation() {
      element.classList.add('is-hidden');
      window.requestAnimationFrame(() => {
        element.style.transitionDelay = `${50 * (i - 1)}ms`;
        element.classList.remove('is-hidden');
      });
    }

    const linkElement = element.querySelector('.breadcrumbs__link');
    const { id } = linkElement.dataset;

    if (location.href.includes(id) && !NAVIGATION.currentId.includes(id)) {
      startAnimation();
    }
  }
}

export default BreadcrumbItem;
