/*
globals TRANSITION_DURATION
*/

class Product {
  constructor(element) {
    const imageElement = element.querySelector('.product-image img');

    function showImage() {
      window.requestAnimationFrame(() => {
        imageElement.classList.remove('is-hidden');
      });
    }

    if (imageElement) {
      imageElement.classList.add('is-hidden');
      if (imageElement.complete && imageElement.offsetWidth > 0) {
        setTimeout(showImage, TRANSITION_DURATION);
      } else {
        imageElement.onload = showImage;
      }
    }
  }
}

export default Product;
