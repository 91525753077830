/* globals PRODUCTS */

class ProductThumb {
  constructor(element) {
    function handleSelectoCompareElementClick() {
      if (self.element.classList.contains('is-active')) {
        self.unsetToCompare();
      } else {
        self.setToCompare();
      }
    }
    const self = this;
    this.element = element;
    this.uid = element.dataset.uid;
    this.category = element.dataset.category;
    this.imageElement = element.querySelector('.product-thumb__image img');
    this.selectCompareElement = element.querySelector('.product-thumb__select-compare');
    this.selectCompareElement.onclick = handleSelectoCompareElementClick;
    this.transitionEventListener;
  }

  show() {
    if (!this.element.classList.contains('is-hidden')) {
      this.element.classList.add('is-hidden');
    }
    this.element.hidden = false;
    this.element.offsetWidth;
    this.element.classList.remove('is-hidden');
  }

  hide() {
    const self = this;
    function setHidden() {
      if (self.element.classList.contains('is-hidden')) { // double check if element is still hidden
        self.element.hidden = true;
      }
      self.element.removeEventListener('transitionend', setHidden);
    }
    if (!self.element.hidden) {
      self.element.classList.add('is-hidden');
      self.element.addEventListener('transitionend', setHidden);
    }
  }

  unsetToCompare() {
    PRODUCTS.compareBar.removeItem(this);
  }

  setToCompare() {
    PRODUCTS.compareBar.addItem(this);
  }
}

export default ProductThumb;
