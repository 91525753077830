/* globals TRANSITION_DURATION */

class Mainnav {
  constructor(element) {
    function handleToggleClick() {
      if (element.classList.contains('is-visible')) {
        self.hide();
      } else {
        self.show();
      }
    }
    function handleBackgroundClick() {
      if (element.classList.contains('is-visible')) {
        self.hide();
      }
    }

    const self = this;
    const mainnavBackgroundElement = document.querySelector('.mainnav-background');
    this.element = element;
    this.mainheaderElement = document.querySelector('.mainheader');
    this.mainnavToggleElement = document.querySelector('.mainnav-toggle');
    this.listElements = element.querySelectorAll('.mainnav__list');
    this.scrollToTopElement = document.querySelector('.scroll-to-top');
    this.activeListElement = [...this.listElements].filter((listElement) => listElement.classList.contains('is-active'))[0];

    this.mainnavToggleElement.addEventListener('click', handleToggleClick);
    mainnavBackgroundElement.addEventListener('click', handleBackgroundClick);
  }

  show() {
    this.element.style.display = 'block';
    window.requestAnimationFrame(() => {
      this.element.classList.add('is-visible');
      this.mainnavToggleElement.classList.add('is-active');
      this.scrollToTopElement.hidden = true;
    });
    this.showHeader();
  }

  hide() {
    const bodyElement = (document.documentElement.scrollTop !== 0) ? document.documentElement : document.body;
    this.element.classList.remove('is-visible');
    this.mainnavToggleElement.classList.remove('is-active');
    setTimeout(() => {
      this.element.style.display = '';
      if (bodyElement.scrollTop > 250) {
        this.scrollToTopElement.hidden = false;
      }
    }, TRANSITION_DURATION);
    this.hideMainheader();
  }

  showHeader() {
    if (window.scrollY !== 0) {
      this.mainheaderElement.style.opacity = '0';
      this.mainheaderElement.style.transform = 'scale(1.05)';
      this.mainheaderElement.style.transitionDuration = '0s';
      this.mainheaderElement.classList.add('is-mainnav-visible');
      window.requestAnimationFrame(() => {
        this.mainheaderElement.style.opacity = '';
        this.mainheaderElement.style.transform = 'scale(1)';
        this.mainheaderElement.style.transitionDuration = '';
      });
    } else {
      this.mainheaderElement.classList.add('is-mainnav-visible');
    }
    setTimeout(() => {
      this.mainheaderElement.style.backgroundColor = 'white';
    }, TRANSITION_DURATION);
  }

  hideMainheader() {
    if (window.scrollY !== 0) {
      this.mainheaderElement.style.backgroundColor = 'transparent';
      this.mainheaderElement.style.opacity = '0';
      this.mainheaderElement.style.transform = 'scale(1.05)';
      this.mainheaderElement.classList.remove('is-mainnav-visible');
      setTimeout(() => {
        this.mainheaderElement.style.backgroundColor = '';
        this.mainheaderElement.style.opacity = '';
        this.mainheaderElement.style.transform = '';
      }, TRANSITION_DURATION);
    } else {
      this.mainheaderElement.style.backgroundColor = 'transparent';
      this.mainheaderElement.classList.remove('is-mainnav-visible');
      setTimeout(() => {
        this.mainheaderElement.style.backgroundColor = '';
      }, TRANSITION_DURATION);
    }
  }

  animateListElemets(id, newActiveUlElement) {
    // handle mainnav animation
    if (newActiveUlElement
    && newActiveUlElement !== this.activeListElement) {
      const self = this;
      const oldActiveUlElement = self.activeListElement;
      oldActiveUlElement.classList.remove('is-active');
      if (id === 'home') {
        oldActiveUlElement.classList.add('animate-fade-out-right');
        newActiveUlElement.classList.add('animate-fade-out-left');
      } else {
        oldActiveUlElement.classList.add('animate-fade-out-left');
        newActiveUlElement.classList.remove('animate-fade-out-left');
      }
      self.activeListElement = newActiveUlElement;
      self.activeListElement.hidden = false;
      self.activeListElement.offsetWidth;
      self.activeListElement.classList.remove('animate-fade-out-left');
      self.activeListElement.classList.remove('animate-fade-out-right');
      self.activeListElement.classList.add('is-active');
      setTimeout(() => {
        oldActiveUlElement.hidden = true;
        [...oldActiveUlElement.querySelectorAll('.mainnav__link.is-active')].forEach((element) => {
          element.classList.remove('is-active');
        });
      }, TRANSITION_DURATION);
    }
  }
}

export default Mainnav;
