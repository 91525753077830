class PopupLink {
  constructor(element) {
    const bodyElement = document.querySelector('body');
    const popupElement = document.createElement('div');
    popupElement.classList.add('popup');
    popupElement.classList.add('is-hidden');
    const imgElement = document.createElement('img');
    imgElement.src = element.href;
    const bttnCloseElement = document.createElement('button');
    bttnCloseElement.classList.add('bttn-close');
    bttnCloseElement.title = 'Popup schließen';

    popupElement.appendChild(imgElement);
    popupElement.appendChild(bttnCloseElement);

    function onKeydown() {
      if (event.key === 'Escape') {
        close();
      }
    }

    function onScroll(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    function open() {
      bodyElement.appendChild(popupElement);
      window.addEventListener('keydown', onKeydown);
      window.addEventListener('scroll', onScroll, false);
      bodyElement.style.overflow = 'hidden';
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          popupElement.classList.remove('is-hidden');
        });
      });
    }

    function close() {
      window.removeEventListener('keydown', onKeydown);
      window.removeEventListener('scroll', onScroll);
      popupElement.classList.add('is-hidden');
      bodyElement.style.overflow = '';
      setTimeout(() => {
        bodyElement.removeChild(popupElement);
      }, 250 * 2);
    }

    bttnCloseElement.addEventListener('click', () => {
      close();
    });
    element.onclick = function (event) {
      event.preventDefault();
      event.stopPropagation();
      if (!window.matchMedia(`(max-width: ${480 / 16}em)`).matches) {
        open();
      }
    };
  }
}

export default PopupLink;
