import scrollTo from '../helper/_scrollTo.js';

class TimelineItem {
  constructor(element, timeline) {
    function handleClick() {
      timeline.activateElement(element);
    }
    const buttonElement = element.querySelector('.timeline__button');
    buttonElement.onclick = handleClick;
  }
}

class Timeline {
  constructor(element) {
    this.element = element;
    this.itemElements = Array.from(element.querySelectorAll('.timeline__item'));
    this.scrollContainer = element.querySelector('.timeline__scroll-container');
    this.listContainer = element.querySelector('.timeline__list-container');
    this.itemElements.forEach((itemElement) => {
      new TimelineItem(itemElement, this);
    });
    const lastItemElement = this.itemElements[this.itemElements.length - 1];
    this.activateElement(lastItemElement);
  }

  removeActiveFromItems() {
    Array.from(this.itemElements).forEach((itemElement) => {
      itemElement.classList.remove('is-active');
    });
  }

  activateElement(element) {
    let scrollToLeft = element.offsetLeft - this.element.offsetWidth / 2 + parseFloat(getComputedStyle(this.listContainer).paddingLeft);
    if (window.matchMedia('(max-width: 40em)').matches) {
      scrollToLeft = element.offsetLeft - parseFloat(getComputedStyle(this.listContainer).paddingLeft) - element.offsetWidth / 2;
    }
    const duration = Math.abs(this.scrollContainer.scrollLeft - scrollToLeft) / 2 + 250;
    const scrollingSettings = {
      element: this.scrollContainer,
      horizontal: true,
      duration,
      destination: scrollToLeft,
    };
    this.removeActiveFromItems();
    scrollTo(scrollingSettings);
    setTimeout(() => {
      element.classList.add('is-active');
    }, duration - 150);
  }
}

export default Timeline;
