import scrollTo from '../helper/_scrollTo.js';

/*
globals MAINNAV
*/

(function () {
  function handleScroll() {
    if (bodyElement.scrollTop > 250) {
      if (!MAINNAV.element.classList.contains('is-visible')) {
        element.hidden = false;
      }
    } else {
      element.hidden = true;
    }
  }
  function handleClick() {
    let duration = bodyElement.scrollTop / 2 + 125;
    duration = duration > 500 ? 500 : duration;
    scrollTo({
      easing: 'easeOutCubic',
      duration,
    });
  }
  const bodyElement = (document.documentElement.scrollTop !== 0) ? document.documentElement : document.body;
  const element = document.querySelector('.scroll-to-top');
  window.addEventListener('scroll', handleScroll);
  element.addEventListener('click', handleClick);
}());
