import ProductThumb from './_ProductThumb.js';
import CompareBar from './_CompareBar.js';
import Compare from './_Compare.js';

class Products {
  constructor() {
    const self = this;

    function initProdcutThumbInstances() {
      const elements = document.querySelectorAll('.product-thumb');
      [...elements].forEach((element) => {
        self.prodcutThumbs.push(new ProductThumb(element));
      });
    }

    this.prodcutThumbs = [];
    this.compareBar = new CompareBar();
    this.compare = new Compare();
    initProdcutThumbInstances();
  }
}

export default Products;
