import scrollTo from '../helper/_scrollTo.js';
import BreadcrumbItem from './_BreadcrumbItem.js';

/*
globals MAINNAV NAVIGATION PRODUCTS INIT TRANSITION_DURATION ga
*/

class Navigation {
  constructor() {
    function handlePopstate(event) {
      // reload page, don’t do fancy stuff, it’s to complicated and breaks sometime – always!
      if (event.state === true) {
        location.reload();
      }
    }

    this.breadcrumbsElement = document.querySelector('.breadcrumbs');
    this.mainElement = document.querySelector('main');
    this.langswitchElement = document.querySelector('.langswitch');
    this.mainContainerElement = this.mainElement.querySelector('.main-container');
    this.contentHeaderElement = this.mainElement.querySelector('.content-header');
    this.currentId = location.pathname.substr(1);
    history.replaceState(true, null, null);

    window.addEventListener('popstate', handlePopstate);

    setTimeout(() => { // make async to access NAVIGATION instance in BreadcrumbItem
      this.createBreadcrumbItems();
    }, 10);
  }

  openLink(url) {
    const self = this;

    // translations
    const productRangeString = document.documentElement.lang === 'de' ? 'Sortiment' : 'product range';
    const allProductsString = document.documentElement.lang === 'de' ? 'Alle Produkte' : 'all products';
    const productOverviewString = document.documentElement.lang === 'de' ? 'Produktübersicht' : 'product overview';

    let pathArray = url.substr(location.origin.length + 1).split('/');
    if (pathArray[0] === 'en') pathArray = pathArray.splice(1);
    const isProduct = !!(pathArray[0] === 'produkte' && pathArray[2]);

    // get id
    let id = 'home';
    // get linkElement
    let linkElement = MAINNAV.element.querySelector(`.mainnav__link[href="${url}"]`);
    if (!linkElement) {
      const parentUrl = url.substr(0, url.lastIndexOf('/'));
      linkElement = MAINNAV.element.querySelector(`.mainnav__link[href="${parentUrl}"]`);
    }
    id = (linkElement) ? linkElement.dataset.id : id;

    // get newActiveUlElement
    let newActiveUlElement = MAINNAV.element.querySelector(`.mainnav__list[data-id="${id}"]`);
    if (!newActiveUlElement) {
      const parentId = id.substr(0, id.lastIndexOf('/'));
      newActiveUlElement = MAINNAV.element.querySelector(`.mainnav__list[data-id="${parentId}"]`);
    }
    if (!newActiveUlElement) {
      newActiveUlElement = MAINNAV.listElements[0];
    }

    MAINNAV.animateListElemets(id, newActiveUlElement);

    self.hideMain();
    self.loadPage(url).then((content) => {
      self.openPage(id, url, content, isProduct);
    }).catch((exception) => {
      console.error(exception);
      location.href = url;
    });
  }

  loadPage(url) {
    const apiUrl = (url === `${location.origin}/en`) ? `${url}/.main` : `${url}.main`;
    return fetch(apiUrl).then((response) => {
      if (response.status === 404) {
        location.href = url;
      } else {
        return response.text();
      }
    }).then((html) => html);
  }

  loadScript(url) {
    const scriptElement = document.createElement('script');
    scriptElement.setAttribute('src', url);
    document.querySelector('head').appendChild(scriptElement);
  }

  loadStyle(url) {
    const linkElement = document.createElement('link');
    linkElement.setAttribute('rel', 'stylesheet');
    linkElement.setAttribute('href', url);
    document.querySelector('head').appendChild(linkElement);
  }

  openPage(id, url, content, isProduct) {
    const self = this;
    const element = document.createElement('div');
    element.innerHTML = content;
    const title = element.querySelector('title').innerText;
    const breadcrumbsElement = element.querySelector('.breadcrumbs');
    const mainElement = element.querySelector('main');
    const mainContainerElement = element.querySelector('.main-container');
    const contentHeaderElement = element.querySelector('.content-header');
    const scriptElement = element.querySelector('script');
    const styleElement = element.querySelector('link[rel="stylesheet"]');

    history.pushState(true, null, url);
    self.updateLangswitch(url);
    scrollTo();
    if (contentHeaderElement && self.contentHeaderElement) {
      const h2 = element.querySelector('h2').innerHTML;
      const h3 = element.querySelector('h3').innerHTML;
      self.updateContentHeader(h2, h3);
    } else if (contentHeaderElement) {
      self.showContentHeader(contentHeaderElement);
    } else {
      self.removeContentHeader();
    }
    self.removeBreadcrumbs(id).then(() => {
      self.updateBreadcrumbs(breadcrumbsElement);
      self.currentId = location.pathname.substr(1);
      INIT();
      setTimeout(() => {
        MAINNAV.hide();
      }, TRANSITION_DURATION * 2);
    });
    setTimeout(() => {
      try {
        self.mainElement.setAttribute('class', mainElement.getAttribute('class'));
        self.mainContainerElement.innerHTML = mainContainerElement.innerHTML;
        self.showMain();
        self.updateTitle(title);
        if (isProduct) {
          url = url.substr(0, url.lastIndexOf('/'));
          self.setToActive(url);
        } else {
          self.setToActive(url);
        }
        if (styleElement) {
          self.loadStyle(styleElement.href);
        }
        if (scriptElement) {
          self.loadScript(scriptElement.src);
        }
        INIT();
        if (typeof ga !== 'undefined') {
          ga('send', {
            hitType: 'pageview',
            page: url.substr(location.origin.length),
            title,
          });
        }
      } catch (exception) {
        console.error(exception);
        location.href = url;
      }
    }, TRANSITION_DURATION);
  }

  updateTitle(title) {
    document.title = title;
  }

  updateBreadcrumbs(_breadcrumbsElement) {
    this.breadcrumbsElement.innerHTML = _breadcrumbsElement.innerHTML;
    this.createBreadcrumbItems();
  }

  updateLangswitch(url) {
    let path = url.substr(location.origin.length);
    if (path.startsWith('/en')) {
      path = path.substring('/en'.length);
    }
    [...this.langswitchElement.querySelectorAll('ul > li a')].forEach((element) => {
      element.href = location.origin + path;

      if (element.hreflang !== 'de') {
        element.href = `${location.origin}/${element.hreflang}${path}`;
      }
    });
  }

  removeBreadcrumbs() {
    const self = this;
    return new Promise((resolve) => {
      const breadcrumbsItemElements = self.breadcrumbsElement.querySelectorAll('.breadcrumbs__item');
      const elementsToHide = [];
      [...breadcrumbsItemElements].forEach((element) => {
        const { id } = element.querySelector('.breadcrumbs__link').dataset;
        if (!location.href.includes(id) && NAVIGATION.currentId.includes(id)
         && id !== 'home') {
          elementsToHide.push(element);
        }
      });
      if (elementsToHide.length > 0) {
        elementsToHide.forEach((element, i) => {
          const delay = 50 * (elementsToHide.length - i);
          element.style.transitionDelay = `${delay}ms`;
          element.classList.add('is-hidden');
          setTimeout(() => {
            element.remove();
            if (i === elementsToHide.length - 1) {
              resolve();
            }
          }, TRANSITION_DURATION + 50 * elementsToHide.length);
        });
      } else {
        resolve();
      }
    });
  }

  updateContentHeader(h2, h3) {
    const self = this;
    const h2Element = self.contentHeaderElement.querySelector('h2');
    const h3Element = self.contentHeaderElement.querySelector('h3');
    h2Element.style.opacity = '0';
    h3Element.style.opacity = '0';
    h2Element.style.transform = 'translateX(-8px)';
    h3Element.style.transform = 'translateX(-4px)';
    setTimeout(() => {
      h2Element.innerHTML = h2;
      h3Element.innerHTML = h3;
      h2Element.style.opacity = '';
      h3Element.style.opacity = '';
      h2Element.style.transform = '';
      h3Element.style.transform = '';
    }, TRANSITION_DURATION);
  }

  showContentHeader(contentHeaderElement) {
    const self = this;
    contentHeaderElement.style.transitionDuration = '0s';
    contentHeaderElement.style.opacity = '0';
    contentHeaderElement.style.transform = 'scale(0.995)';
    setTimeout(() => {
      self.mainElement.insertBefore(contentHeaderElement, self.mainElement.firstChild);
      window.requestAnimationFrame(() => {
        contentHeaderElement.style.transitionDuration = '';
        contentHeaderElement.style.opacity = '';
        contentHeaderElement.style.transform = '';
      });
    }, TRANSITION_DURATION);
    this.contentHeaderElement = contentHeaderElement;
  }

  removeContentHeader() {
    if (this.contentHeaderElement) {
      const { contentHeaderElement } = this;
      const h2Element = this.contentHeaderElement.querySelector('h2');
      const h3Element = this.contentHeaderElement.querySelector('h3');
      h2Element.style.transform = 'translateX(-8px)';
      h3Element.style.transform = 'translateX(-4px)';
      contentHeaderElement.style.opacity = '0';
      contentHeaderElement.style.transform = 'scale(0.995)';
      setTimeout(() => {
        contentHeaderElement.remove();
        this.contentHeaderElement = false;
      }, TRANSITION_DURATION);
    }
  }

  createBreadcrumbItems() {
    [...this.breadcrumbsElement.querySelectorAll('.breadcrumbs__item')].forEach((element, i) => {
      new BreadcrumbItem(element, i);
    });
  }

  setToActive(url) {
    [...MAINNAV.activeListElement.querySelectorAll('.is-active')].forEach((element) => {
      element.classList.remove('is-active');
    });

    if (MAINNAV.activeListElement.querySelector(`a[href="${url}"]`)) {
      MAINNAV.activeListElement.querySelector(`a[href="${url}"]`).classList.add('is-active');
    }
  }

  hideMain() {
    this.mainElement.classList.add('is-hidden');
  }

  showMain() {
    this.mainElement.classList.remove('is-hidden');

    if (window.IS_KEYBOARD_USER === true) {
      this.mainElement.setAttribute('tabindex', '0');
      this.mainElement.focus();
    }
  }
}

export default Navigation;
