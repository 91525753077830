import 'airbnb-browser-shims/browser-only';

import './defaults/_globals.js';
import './helper/_isKeyboardUser.js';
import isTouchDevice from './helper/_isTouchDevice.js';
import Mainnav from './components/_mainnav.js';
import Navigation from './components/_Navigation.js';
import Link from './components/_Link.js';
import Products from './components/_Products.js';
import Product from './components/_Product.js';
import Timeline from './components/_Timeline.js';
import Detail from './components/_Detail.js';
import ProductsTeaser from './components/_ProductsTeaser.js';
import PopupLink from './components/_PopupLink.js';
import './components/_ScrollToTop.js';

/*
globals isTouchDevice Mainnav Navigation Link Products Timeline Detail ProductsTeaser Product PopupLink klaro klaroConfig
*/

window.MAINNAV = new Mainnav(document.querySelector('.mainnav'));
window.NAVIGATION = new Navigation();
window.PRODUCTS;

window.INIT = function () {
  const linkElements = document.querySelectorAll('a[href]');
  [...linkElements].forEach((element) => {
    // don’t include langswitch and popup link elements
    if (!element.hasAttribute('hreflang') && !element.classList.contains('popup-link')) {
      new Link(element);
    }
  });

  if (document.querySelector('.products')) {
    window.PRODUCTS = new Products();
  }

  const productElement = document.querySelector('.product');
  if (productElement) {
    new Product(productElement);
  }

  const timelineElement = document.querySelector('.timeline');
  if (timelineElement) {
    new Timeline(timelineElement);
  }

  const detailElements = document.querySelectorAll('.detail');
  if (detailElements) {
    Array.from(detailElements).forEach((element) => {
      new Detail(element);
    });
  }

  const productsTeaserElement = document.querySelector('.products-teaser');
  if (productsTeaserElement) {
    new ProductsTeaser(productsTeaserElement);
  }

  const popupLinkElements = document.querySelectorAll('.popup-link');
  Array.from(popupLinkElements).forEach((element) => {
    new PopupLink(element);
  });

  // apply klaro consent
  if (klaro && klaroConfig) {
    setTimeout(() => {
      const manager = klaro.getManager(klaroConfig);
      manager.applyConsents();
      klaro.render(klaroConfig);
    }, 200);
  }
};

window.INIT();

if (isTouchDevice()) {
  document.body.classList.add('is-touch');
}
