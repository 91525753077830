/* globals PRODUCTS MAINNAV TRANSITION_DURATION */

class CompareBar {
  constructor() {
    function handleScroll() {
      self.updatePosition();
    }
    const self = this;
    this.element = document.querySelector('.compare-bar');
    this.contentHeaderElement = document.querySelector('.content-header');
    this.bttn = this.element.querySelector('.bttn');
    this.itemElements = [...this.element.querySelectorAll('.compare-bar__item')];
    this.productThumbs = [];
    this.element.classList.add('is-hidden');
    window.onscroll = handleScroll;
  }

  updatePosition() {
    if (window.matchMedia('(max-width: 48em)').matches) {
      if (window.scrollY < MAINNAV.mainheaderElement.offsetHeight) {
        let y = MAINNAV.mainheaderElement.offsetHeight - window.scrollY;
        if (window.scrollY < 0) {
          y = MAINNAV.mainheaderElement.offsetHeight;
        }
        this.element.style.transform = `translateY(${y}px)`;
        this.element.offsetWidth;
        this.element.style.transitionDuration = '0s';
      } else {
        this.element.style.transform = '';
        this.element.offsetWidth;
        this.element.style.transitionDuration = '';
      }
    }
  }

  show() {
    this.element.hidden = false;
    window.requestAnimationFrame(() => {
      this.contentHeaderElement.classList.add('is-inactive');
      this.element.classList.remove('is-hidden');
      this.updatePosition();
    });
  }

  hide() {
    this.element.style.transitionDuration = '';
    this.element.style.transform = '';
    this.element.offsetWidth;
    this.contentHeaderElement.classList.remove('is-inactive');
    this.element.classList.add('is-hidden');
    setTimeout(() => {
      this.element.hidden = true;
    }, TRANSITION_DURATION);
  }

  updateBttn() {
    let href = '#vergleiche=';
    const uids = [];
    if (this.productThumbs.length > 0) {
      this.productThumbs.forEach((productThumb) => uids.push(productThumb.uid));
      href += uids.toString();
      this.bttn.href = href;
    } else {
      this.bttn.href = '';
    }
    if (this.productThumbs.length > 1) {
      this.bttn.removeAttribute('aria-disabled');
    } else {
      this.bttn.setAttribute('aria-disabled', 'true');
    }
  }

  createItemContent(productThumb) {
    let html = '';
    html += `<img src="${productThumb.imageElement.src}">`;
    return html;
  }

  reorderItems() {
    this.itemElements.forEach((element, index) => {
      if (this.productThumbs[index]) {
        const productThumb = this.productThumbs[index];
        if (productThumb.uid !== element.dataset.uid) {
          const innerHTML = this.createItemContent(this.productThumbs[index]);
          element.innerHTML = innerHTML;
          element.dataset.uid = productThumb.uid;
        }
      } else {
        element.innerHTML = '';
      }
    });
  }

  addItem(productThumb) {
    const self = this;
    function handleItemClick() {
      try {
        const productThumb = self.productThumbs.filter((thumb) => thumb.uid === this.dataset.uid)[0];
        self.removeItem(productThumb);
      } catch (exception) {
        console.info(exception);
      }
    }
    if (self.element.hidden) {
      self.show();
    }
    if (self.productThumbs.length < 3) {
      self.productThumbs.push(productThumb);
      const itemElement = self.itemElements[self.productThumbs.length - 1];
      const innerHTML = self.createItemContent(productThumb);
      itemElement.innerHTML = innerHTML;
      itemElement.dataset.uid = productThumb.uid;
      itemElement.onclick = handleItemClick;
      self.updateBttn();
      productThumb.element.classList.add('is-active');
    }
  }

  removeItem(productThumb) {
    const index = this.productThumbs.indexOf(productThumb);
    const itemElement = this.itemElements[index];
    if (itemElement) {
      this.productThumbs.splice(index, 1);
      itemElement.innerHTML = '';
      itemElement.removeAttribute('data-uid');
      this.reorderItems();
      this.updateBttn();
      productThumb.element.classList.remove('is-active');
      if (this.productThumbs.length === 0) {
        this.hide();
      }
    }
  }

  clear() {
    const { productThumbs } = this;
    productThumbs.forEach((productThumb) => {
      productThumb.element.classList.remove('is-active');
    });
    this.itemElements.forEach((itemElement) => {
      itemElement.innerHTML = '';
      itemElement.dataset.uid = '';
    });
    this.productThumbs = [];
    this.updateBttn();
    this.hide();
  }
}

export default CompareBar;
