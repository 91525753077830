window.IS_KEYBOARD_USER = false;

function handleFirstTab(event) {
  if (event.keyCode === 9) { // the "I am a keyboard user" key
    window.IS_KEYBOARD_USER = true;
    window.removeEventListener('keydown', handleFirstTab);
  }
}

window.addEventListener('keydown', handleFirstTab);
